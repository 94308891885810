/**
 * Dependencies for Remote Components
 */
module.exports = {
	resolve: {
		react: require('react'),
		classnames: require('classnames'),
		'prop-types': require('prop-types'),
		'react-svg': require('react-svg'),
		'react-device-detect': require('react-device-detect'),
		'react-redux': require('react-redux'),
		'redux-form': require('redux-form'),
		redux: require('redux'),
	},
};
